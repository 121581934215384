import * as React from 'react';
import { Box, BoxProps } from '@mantine/core';

import * as s from './AnchorTarget.module.css';

interface AnchorTarget extends React.PropsWithChildren<BoxProps> {
  id?: string;
}

export const AnchorTarget = (props: AnchorTarget) => {
  const { children, ...rest } = props;
  return (
    <Box component="div" {...rest} className={s.AnchorTarget}>
      {children}
    </Box>
  );
};
