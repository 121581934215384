import { Route } from '../utils/Route';

export const appRoutes = {
  index: new Route('/'),
  recipes: {
    index: new Route('/przepisy'),
    meal: new Route('/przepisy/:meal'),
    category: new Route('/przepisy/:meal/:category?'),
    details: new Route('/przepisy/:meal/:category?/:slug'),
  },
  offer: {
    index: new Route('/oferta'),
    collab: new Route('/oferta/wspolpraca'),
    consultation: new Route('/oferta/konsultacja-dietetyczna-online'),
    dietPregnant: new Route('/oferta/swiadoma-dieta-w-ciazy'),
    dietTetany: new Route('/oferta/gotowe-diety/dieta-w-tezyczce'),
    dietTetanyPreorder: new Route('/oferta/gotowe-diety/dieta-w-tezyczce/promocja-przedpremierowa'),
    diets: {
      index: new Route('/oferta/gotowe-diety'),
      product: new Route('/oferta/gotowe-diety/:slug'),
    },
  },
  blog: {
    index: new Route('/blog'),
    post: new Route('/blog/:slug'),
  },
  opinions: {
    index: new Route('/opinie'),
  },
  terms: new Route('/regulamin'),
  policy: new Route('/polityka-prywatnosci'),
  shop: {
    cart: new Route('/sklep/koszyk'),
  },
};

export default appRoutes;
