import * as React from 'react';
import { Group, List, MantineColor, rem, SimpleGrid, ThemeIcon } from '@mantine/core';
import { IconThumbDown, IconThumbUp } from '@tabler/icons-react';

import { Text } from 'shared/components/atoms';
import { SectionTitle } from 'shared/components/molecules';

import * as s from './ProsCons.module.css';

interface ProsConsProps {
  pros: {
    title: string;
    items: string[];
  };
  cons: {
    title: string;
    items: string[];
  };
  color?: MantineColor;
}

export const ProsCons = (props: ProsConsProps) => {
  const { pros, cons, color = 'primary' } = props;
  return (
    <>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing="xl">
        <div>
          <SectionTitle
            pb={3}
            color={color}
            title={pros.title}
            subline="Zalecenia"
            classNames={{ title: s.title }}
          />
          <List spacing="md">
            {pros.items.map((item) => (
              <List.Item key={item}>
                <Group gap="sm" wrap="nowrap">
                  <ThemeIcon variant="light" size={rem(40)} color="green">
                    <IconThumbUp />
                  </ThemeIcon>
                  <Text>{item}</Text>
                </Group>
              </List.Item>
            ))}
          </List>
        </div>
        <div>
          <SectionTitle
            pb={3}
            color={color}
            title={cons.title}
            subline="Przeciwwskazania"
            classNames={{ title: s.title }}
          />
          <List spacing="md">
            {cons.items.map((item) => (
              <List.Item key={item}>
                <Group gap="sm" wrap="nowrap">
                  <ThemeIcon variant="light" size={rem(40)} color="red">
                    <IconThumbDown />
                  </ThemeIcon>
                  <Text>{item}</Text>
                </Group>
              </List.Item>
            ))}
          </List>
        </div>
      </SimpleGrid>
    </>
  );
};
