import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Badge, Box, Card, Divider, Group, Title } from '@mantine/core';

import { Text } from 'shared/components/atoms';

import { CheckoutSession } from '../../types';
import { CheckoutCheckboxes } from './CheckoutCheckboxes';

import * as s from './CheckoutSummary.module.css';

const messages = {
  title: 'Podsumowanie',
  subtotal: 'Wartość produktów',
  total: 'Do zapłaty',
  discount: 'Rabat',
  couponInfo: 'Jeśli posiadasz kod rabatowy, możesz go zastosować w następnym kroku.',
  maintananceBreak:
    'Trwa przerwa techniczna. W tym momencie nie można dokonać zakupu. Za utrudnienia przepraszamy. Spróbuj wrócić za chwilę.',
};

const isMaintanance = false;

interface CheckoutSummaryProps {
  checkoutSession: CheckoutSession;
}

export const CheckoutSummary = (props: CheckoutSummaryProps) => {
  const { checkoutSession } = props;

  return (
    <Card bg="gray.1" radius="md" px="md" py="lg">
      <Title order={3} size="h4" pb="sm">
        {messages.title}
      </Title>
      <Text c="gray.8" fz="sm">
        <Group component="span" justify="space-between" pb="sm">
          <span>{messages.subtotal}</span>
          <span>{checkoutSession.productsSubtotalPrice.format()}</span>
        </Group>
      </Text>
      {checkoutSession.discountApplications.length > 0 ? (
        <div>
          <Text fz="sm" fw="bold" ff="heading">
            {messages.discount}
          </Text>
          {checkoutSession.discountApplications.map(({ title, value }) => (
            <Text key={title} c="gray.8" fz="sm">
              <Group component="span" justify="space-between" pb="sm">
                <span>{title}</span>
                <Badge variant="light" color="gray">
                  -{value.format()}
                </Badge>
              </Group>
            </Text>
          ))}
        </div>
      ) : null}
      <Text fz="xl" fw="bold" ff="heading">
        <Group component="span" justify="space-between" pb="lg">
          <span>{messages.total}</span>
          <span>{checkoutSession.totalPrice.format()}</span>
        </Group>
      </Text>
      <Text c="gray.8" fz="xs" nbsp>
        {messages.couponInfo}
      </Text>
      <Divider color="gray.3" my="md" />
      <Box pos="relative">
        {isMaintanance ? (
          <Text c="red" pt="md">
            {messages.maintananceBreak}
          </Text>
        ) : (
          <CheckoutCheckboxes />
        )}
      </Box>

      <Group justify="center" align="center">
        <StaticImage
          src="../../../../assets/images/p24-logo.png"
          alt="Przelewy24"
          placeholder="none"
          height={20}
          className={s.PaymentLogo}
        />
        <StaticImage
          src="../../../../assets/images/blik-logo.png"
          alt="Blik"
          placeholder="none"
          height={20}
          className={s.PaymentLogo}
        />
      </Group>
    </Card>
  );
};

export const CheckoutSummarySkeleton = () => {
  return (
    <Card bg="gray.1" radius="md" px="md" py="lg">
      <Title order={3} size="h4" pb="lg">
        {messages.title}
      </Title>
    </Card>
  );
};

CheckoutSummary.Skeleton = CheckoutSummarySkeleton;
