import * as React from 'react';
import { Select, Stack } from '@mantine/core';

import { useProductVariantContext } from './ProductVariant';

export const ProductVariantSelect = () => {
  const { selectedOptions, selectOption, options } = useProductVariantContext();

  return (
    <Stack gap="sm">
      {options.map(({ name, options }, index) => (
        <div key={name}>
          <Select
            label={name}
            data={options}
            onChange={selectOption(name)}
            defaultValue={selectedOptions[index].value}
            allowDeselect={false}
            size="md"
          />
        </div>
      ))}
    </Stack>
  );
};
