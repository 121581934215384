module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.0__react-dom@1_3eldazharttwnoezdnu4lxfp44/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-NLBK5KS7B8","cookieName":"jzzr_ga"},"googleTagManager":{"trackingId":"GTM-KBBCD6B","cookieName":"jzzr_gtm"},"facebookPixel":{"pixelId":"916973072304938","cookieName":"jzzr_fbq"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3_r2rltksiqwsayj34m4lvojb2qq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jedz Zdrowo Żyj Różowo","short_name":"JRóżowo","start_url":"/","lang":"pl-PL","background_color":"#ffffff","display":"standalone","icon":"src/assets/logo/jzzr-logo-transparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d11c74715af9b0d51f8ea6f3d74f4897"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__cs5km2fv2ttlox34dflbc46z4a/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
