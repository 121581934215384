import * as React from 'react';
import { Badge, Box, Group, Highlight, Paper, SimpleGrid, Stack, Text } from '@mantine/core';
import { IconArrowNarrowRight } from '@tabler/icons-react';

import { Img, Link } from 'shared/components/atoms';
import { nbspScript } from 'shared/utils';
import { ProductShort } from 'domain/shop/types';

import * as s from './ProductBanner.module.css';

const messages = {
  cta: 'Dowiedz się więcej',
  badge: 'Nowość',
  headline: {
    text: 'Zapomnij o uciążliwych objawach tężyczki i w końcu poczuj się dobrze!',
    highlight: 'poczuj się dobrze!',
  },
};

interface ProductBannerProps extends ProductShort {}

export function ProductBanner(props: ProductBannerProps) {
  const { image, title, price, url } = props;
  return (
    <Paper radius="lg" bg="tetany.0" p="lg" className={s.Paper}>
      <div className={s.Circle} />
      <SimpleGrid cols={{ base: 1, sm: 2 }} pos="relative" spacing="xl">
        <Stack gap="lg" justify="center">
          <Stack gap="sm">
            <Group>
              <Text ff="heading" fz="lg">
                {title}
              </Text>
              <Badge variant="light" color="tetany">
                {messages.badge}
              </Badge>
            </Group>

            <Highlight
              highlight={messages.headline.highlight}
              highlightStyles={{
                color: 'var(--mantine-color-tetany-8)',
                background: 'transparent',
              }}
              className={s.Headline}
              ff="heading"
            >
              {nbspScript(messages.headline.text)}
            </Highlight>
          </Stack>

          <Group>
            <Link to={url} className={s.Cta}>
              <span>{messages.cta}</span>
              <IconArrowNarrowRight />
            </Link>
          </Group>
        </Stack>

        <Group justify="center" pos="relative">
          <Box maw={240}>
            <Img image={image} alt={title} />
          </Box>
          <div className={s.PriceContainer}>
            <Text ff="heading" fw="bold" fz="sm">
              {price.format()}
            </Text>
          </div>
        </Group>
      </SimpleGrid>
    </Paper>
  );
}
