import * as React from 'react';
import { Group } from '@mantine/core';

import { Text } from 'shared/components/atoms';

import { useProductVariantContext } from './ProductVariant';

export const ProductVariantPrice = () => {
  const { selectedOptions, variants } = useProductVariantContext();
  const currentVariant = variants.find((variant) => variant.isSelected(selectedOptions));

  return currentVariant ? (
    currentVariant.compareAtPrice ? (
      <Group align="center">
        <Text ff="heading" fz="24px" fw="bold" c="primary">
          {currentVariant.compareAtPrice.format()}
        </Text>
        <Text ff="heading" fz="md" fw="bold" c="dimmed" td="line-through">
          {currentVariant.price.format()}
        </Text>
      </Group>
    ) : (
      <Text ff="heading" fz="24px" fw="bold">
        {currentVariant.price.format()}
      </Text>
    )
  ) : null;
};
